<template>
    <div class="about">
        <div class="member-head">
            <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
        </div>
        <div class="common">
            <div class="common-container MyRegistration-container">
                <div class="stop-one">
                    <h4 class="stop">已结束</h4>
                </div>
                <div class="MyRegistration-content">
                    <div class="MyRegistration-img">
                        <img src="../assets/images/u817.svg"/>
                    </div>
                    <div class="activtry">
                        <div class="activtry-name">
                            <span>活动名称:</span>
                            <span>眼科医生活动</span>
                        </div>
                        <div class="activtry-time">
                            <span>活动时间:</span>
                            <span>2021/08/01 9:00 至 2021/08/31 18:00</span>
                        </div>
                        <div class="activtry-address">
                            <span>活动地址:</span>
                            <span>上海市</span>
                        </div>
                    </div>
                </div>
                <div class="MyRegistration-customerInfo">
                    <div class="customer">
                        <span class="same">姓名:</span>
                        <span >李光洙</span>
                        <span class="same">手机:</span>
                        <span >13764476588</span>
                        <span class="same">参加人数:</span>
                        <span>3</span>
                    </div>
                    <div class="shenpi">
                        <span>审批状态:</span>
                        <span class="pass">通过</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyRegistration',
    methods: {
        goHistotry() {
            this.$router.go(-1)
        },
    },
}
</script>

<style lang="less" scoped>
@import '../assets/css/member.less';
</style>